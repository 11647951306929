import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p style={{ position: 'relative', marginTop: 100 }}>
          Hello, This is Tushar Batra.
        </p>
        <p>
        This website is under development. Please come back later.
        </p>
      </header>
    </div>
  );
}

export default App;
